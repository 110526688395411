import React, { useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, InferType, array, object } from 'yup';
import CnMammoBirads from './components/CnMammoBirads';
import CnEditor from './components/CnEditor';
import CnAttachments from './components/CnAttachments';
import DownloadPdfBtn from './components/btns/DownloadPdfBtn';
import SaveTemplateBtn from './components/btns/SaveTemplateBtn';
import CopyTextBtn from './components/btns/CopyTextBtn';
import { appendAttachmentsBase } from './utils';
import { useGetStudy } from '../../../../../platform/app/src/api/studies';
import { generalFormSchema } from './CnDefault';
import CnMammoXRay from './components/CnMammoXRay';
import CnMammoRecs from './components/CnMammoRecs';
import NewVisitModal from '../../../../../platform/components/NewVisitModal';
import CnMammoLocs from './components/CnMammoLocs';
import Drawer from '../../../../../platform/components/Drawer';
import type { AppRouter } from '../../../../../../medreview/server/src';
import { inferRouterOutputs } from '@trpc/server';
import { trpc } from '../../../../../platform/app/trpc';
import { modals } from '@mantine/modals';
import { Tabs, Textarea, Button } from '@mantine/core';
import { SubmitMammoBtn } from './components/btns/SubmitCnBtn';
import { notifications } from '@mantine/notifications';
import { DicomMetadataStore } from '@ohif/core';
import { Decimal } from 'decimal.js';

export default function CnMammo() {
  const [wantsToEdit, setWantsToEdit] = useState(false);

  const user = trpc.general.getUser.useQuery();
  const study = useGetStudy();
  const currentStudyUid = new URLSearchParams(window.location.search).get('StudyInstanceUIDs');
  const studyMetadata: any = DicomMetadataStore.getStudy(currentStudyUid);

  const dicomEntranceDoseInmGy = useMemo(() => {
    if (
      !studyMetadata?.series?.find(
        s => s.instances?.some(i => i.EntranceDoseInmGy || i.EntranceDoseInmGy === 0)
      )
    ) {
      return '';
    }

    const res =
      studyMetadata?.series?.reduce(
        (acc, s) =>
          new Decimal(acc).plus(
            new Decimal(
              (s.instances || []).reduce(
                (innerAcc, i) => new Decimal(innerAcc).plus(new Decimal(+i.EntranceDoseInmGy || 0)),
                0
              )
            )
          ),
        0
      ) || 0;

    return new Decimal(res).mul(new Decimal(0.2)).mul(new Decimal(0.12)).toFixed(2).toString();
  }, [studyMetadata?.series]);

  const firstReaderRes = study.data?.pre_conclusion[0]?.result_mammo[0];

  const firstReaderForm = useForm({
    resolver: yupResolver(firstReaderSchema),
    values: study.data
      ? {
          ...getFormVal(study.data, 'first_reader'),
          entranceDoseInmGy: firstReaderRes
            ? firstReaderRes?.entrance_dose_in_mgy
            : dicomEntranceDoseInmGy,
          localization_right: String(firstReaderRes?.localization_right_data?.id || ''),
          localization_left: String(firstReaderRes?.localization_left_data?.id || ''),

          x_ray_density: {
            right: String(
              firstReaderRes?.x_ray_density?.right || ''
            ) as (typeof xRayDensities)[number],
            left: String(
              firstReaderRes?.x_ray_density?.left || ''
            ) as (typeof xRayDensities)[number],
          },
          mg_quality: {
            right: firstReaderRes?.mg_quality?.right || 'GOOD',
            left: firstReaderRes?.mg_quality?.left || 'GOOD',
          } as const,
        }
      : undefined,
  });

  const secondReaderForm = useForm({
    resolver: yupResolver(mammoFormSchema),
    values: study.data ? { ...getFormVal(study.data, 'second_reader') } : undefined,
  });

  const createMammo = trpc.mammo.create.useMutation({
    onSettled: (_, error) => {
      modals.closeAll();
      setWantsToEdit(false);

      notifications?.show({
        message: error
          ? 'Произошла ошибка при сохранении заключения. Обратитесь к администратору системы для решения проблемы'
          : 'Заключение успешно сохранено',
        color: error ? 'red' : 'green',
        autoClose: 5000,
      });

      if (!error) {
        study.refetch();
        firstReaderForm.setValue('mammo_pic', '');
        secondReaderForm.setValue('mammo_pic', '');
      }
    },
  });

  async function onSubmit(data: CnFirstMammoForm, send_message: boolean) {
    const a = await appendAttachmentsBase(data['attachments']);

    createMammo.mutate({
      send_message,
      study_id: study.data!.id,
      conclusion_text: data.conclusion_text,
      birads_code_left: data.birads_code_left ? +data.birads_code_left : null,
      birads_code_right: data.birads_code_right ? +data.birads_code_right : null,
      localization_left: data.localization_left ? +data.localization_left : undefined,
      localization_right: data.localization_right ? +data.localization_right : undefined,

      entranceDoseInmGy: data.entranceDoseInmGy || null,
      mg_quality: data.mg_quality
        ? {
            right: data.mg_quality.right || null,
            left: data.mg_quality.left || null,
          }
        : undefined,
      x_ray_density: data.x_ray_density
        ? {
            right: data.x_ray_density.right || null,
            left: data.x_ray_density.left || null,
          }
        : undefined,
      recs: data.recs.length ? data.recs.join(' ||| ') : null,
      note: data.note || null,
      existing_attachments: data['attachments'].filter(a => a.type === 'EXISTING').map(a => a.url),
      attachments: a,
      mammo_pic: data.mammo_pic ? data.mammo_pic.substring(data.mammo_pic.indexOf(',') + 1) : null,
      check_value: data.check_value ? +data.check_value : null,
    });
  }

  function getAccess(
    allowedSlug: string,
    cn?:
      | NonNullable<inferRouterOutputs<AppRouter>['study']['get']>['pre_conclusion'][number]
      | NonNullable<inferRouterOutputs<AppRouter>['study']['get']>['conclusion'][number]
  ) {
    const canEdit =
      user.data?.canMakeConclusions &&
      user.data?.role_user.some(ru => ru.roles?.slug === allowedSlug) &&
      (allowedSlug === 'second_reader_rentgenolog' && !study.data?.pre_conclusion[0]
        ? false
        : true);

    return { canEdit, disabled: !canEdit || createMammo.isLoading || (!!cn && !wantsToEdit) };
  }

  return (
    <div className="mt-3">
      <Tabs
        defaultValue={
          user.data?.roleHelper.isFirstReader
            ? 'first_reading'
            : user.data?.roleHelper.isSecondReader
            ? 'second_reading'
            : 'questionnaire'
        }
      >
        <Tabs.List>
          <Tabs.Tab value="questionnaire">Анкета</Tabs.Tab>
          <Tabs.Tab value="first_reading">Первое чтение</Tabs.Tab>
          <Tabs.Tab value="second_reading">Второе чтение</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="questionnaire">
          {study.data && (
            <div className="pl-5 pr-8 pb-5">
              <NewVisitModal
                study={{ ...study.data, is_mammo: true, modalities: '' }}
                showPartial="mammo"
              />
            </div>
          )}
        </Tabs.Panel>

        {(
          [
            {
              title: 'Первое чтение',
              value: 'first_reading',
              formProps: firstReaderForm,
              access: getAccess('first_reader_rentgenolog', study.data?.pre_conclusion[0]),
              cn: study.data?.pre_conclusion[0],
            },
            {
              title: 'Второе чтение',
              value: 'second_reading',
              formProps: secondReaderForm,
              access: getAccess('second_reader_rentgenolog', study.data?.conclusion[0]),
              cn: study.data?.conclusion[0],
            },
          ] as const
        ).map(side => {
          return (
            <Tabs.Panel
              value={side.value}
              key={side.value}
            >
              <>
                <div className="pl-5 pr-8 pb-5">
                  <FormProvider {...side.formProps}>
                    {side.value === 'first_reading' && (
                      <CnMammoXRay disabled={side.access.disabled} />
                    )}
                    <CnMammoBirads disabled={side.access.disabled} />
                    {side.value === 'first_reading' && (
                      <CnMammoLocs disabled={side.access.disabled} />
                    )}
                    <CnEditor disabled={side.access.disabled} />
                    <CnAttachments disabled={side.access.disabled} />
                    <div className="mt-12 flex gap-3">
                      <div className="w-[224px]">
                        <Drawer
                          previewImg={
                            side.cn?.result_mammo[0]?.mammo_pic
                              ? `${process.env.S3_URL}/${side.cn.result_mammo[0].mammo_pic}`
                              : null
                          }
                          bgImg="/assets/app/mammo.png"
                          onSave={urlData => side.formProps?.setValue('mammo_pic', urlData)}
                        />
                      </div>

                      <div className="w-[calc(100%-236px)]">
                        <Textarea
                          {...side.formProps.register('note')}
                          placeholder="Примечание"
                          classNames={{ input: 'min-h-[307px]' }}
                          error={side.formProps.formState.errors.note?.message}
                          readOnly={side.access.disabled}
                        />
                      </div>
                    </div>
                    <CnMammoRecs disabled={side.access.disabled} />

                    <div className="mt-4 flex items-center justify-between gap-3">
                      <div className="flex items-center gap-3">
                        <DownloadPdfBtn
                          conclusion={study.data?.conclusion[0]}
                          study={study.data}
                        />
                        <SaveTemplateBtn study={study.data} />
                        <CopyTextBtn />
                      </div>

                      {!side.access.disabled && (
                        <SubmitMammoBtn
                          onSubmit={onSubmit}
                          previewMetadata={{
                            first: firstReaderForm,
                            second: secondReaderForm,
                          }}
                          study={study.data!}
                        />
                      )}

                      {side.access.canEdit && side.access.disabled && (
                        <Button
                          size="xs"
                          onClick={() => setWantsToEdit(true)}
                        >
                          Редактировать
                        </Button>
                      )}
                    </div>
                  </FormProvider>
                </div>
              </>
            </Tabs.Panel>
          );
        })}
      </Tabs>
    </div>
  );
}

const mgQualities = ['BAD', 'GOOD', 'INCORRECT'] as const;
const xRayDensities = ['A', 'B', 'C', 'D'] as const;

const mammoFormSchema = generalFormSchema.shape({
  birads_code_right: string().required(),
  birads_code_left: string().required(),
  recs: array().of(string().required()).required(),
  note: string(),
  mammo_pic: string(),
  check_value: string().notRequired(),
});

const firstReaderSchema = mammoFormSchema.shape({
  entranceDoseInmGy: string(),
  localization_right: string(),
  localization_left: string(),
  mg_quality: object({
    right: string<(typeof mgQualities)[number]>().defined().oneOf(mgQualities),
    left: string<(typeof mgQualities)[number]>().defined().oneOf(mgQualities),
  }),
  x_ray_density: object({
    right: string<(typeof xRayDensities)[number]>(),
    left: string<(typeof xRayDensities)[number]>(),
  }),
});

export type CnMammoForm = InferType<typeof mammoFormSchema>;
export type CnFirstMammoForm = InferType<typeof firstReaderSchema>;

function getFormVal(
  study: NonNullable<inferRouterOutputs<AppRouter>['study']['get']>,
  side: 'first_reader' | 'second_reader'
) {
  const cn = side === 'first_reader' ? study.pre_conclusion[0] : study.conclusion[0];

  return {
    conclusion_text: cn?.conclusion_text || '',
    attachments:
      cn?.conclusion_images.map(ci => {
        return { url: ci.image_path || '', type: 'EXISTING' as const };
      }) || [],
    birads_code_right: String(cn?.result_mammo[0]?.birads_right?.id || ''),
    birads_code_left: String(cn?.result_mammo[0]?.birads_left?.id || ''),
    recs: cn?.result_mammo[0]?.recs?.split(' ||| ') || [],
    note: cn?.result_mammo[0]?.note || '',
    mammo_pic: '',
    check_value: cn?.result_mammo[0]?.check_value?.toString() || '',
  };
}
