import React from 'react';
import { servicesManager } from '../../../../../../platform/app/src/App';
import { useFormContext } from 'react-hook-form';
import { CnMammoForm } from '../CnMammo';
import { Viewer } from './Viewer';
import { getActiveViewportEnabledElement } from '../../../../../../extensions/cornerstone/src';
import { getClipboardImgBlob } from '../utils';
import { attachmentTypes } from '../CnDefault';
import { Tooltip, ActionIcon } from '@mantine/core';
import { IconFocusCentered, IconPhotoScan, IconX } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';

export default function CnAttachments({ disabled }: { disabled: boolean }) {
  const { viewportGridService } = servicesManager.services;

  const { setValue, watch } = useFormContext<CnMammoForm>();
  const attachments = watch('attachments');

  function addCornerstoneAttachment() {
    const enabledElem = getActiveViewportEnabledElement(viewportGridService);
    const imageId = enabledElem.viewport.getCurrentImageId();
    const properties = enabledElem.viewport.getProperties();

    if (attachments.find(a => a.type === 'CORNERSTONE' && a.url === imageId)) {
      notifications.show({
        message: 'Данный кадр уже добавлен в заключение',
        color: 'orange',
      });

      return;
    }

    setValue('attachments', [
      ...attachments,
      {
        type: 'CORNERSTONE',
        url: imageId,
        metadata: { properties },
      },
    ]);
  }

  async function addClipboardAttachment() {
    const blob = await getClipboardImgBlob();
    if (!blob) {
      return;
    }

    const url = URL.createObjectURL(blob);

    setValue('attachments', [...attachments, { type: 'CLIPBOARD', url, metadata: { blob } }]);
  }

  function removeAttachment(url: string, type: (typeof attachmentTypes)[number]) {
    setValue(
      'attachments',
      attachments.filter(a => {
        return a.type !== type || a.url !== url;
      })
    );
  }

  return (
    <div className="mt-7 min-h-[130px] rounded border border-[#373A40] p-3">
      <div className="flex gap-2">
        <ActionIcon
          variant="filled"
          onClick={addCornerstoneAttachment}
          className="p-[2px]"
        >
          <Tooltip label="Вставить текущий кадр в заключение">
            <IconFocusCentered />
          </Tooltip>
        </ActionIcon>

        <ActionIcon
          variant="filled"
          onClick={addClipboardAttachment}
          className="p-[2px]"
        >
          <Tooltip label="Вставить снимок из буфера обмена">
            <IconPhotoScan />
          </Tooltip>
        </ActionIcon>
      </div>

      <div className="mt-3">
        <div
          className="flex flex-wrap gap-3"
          id="cn-attachments"
        >
          {attachments.map(a => {
            return (
              <div key={a.type + a.url}>
                {a.type === 'CORNERSTONE' && (
                  <div onClick={() => removeAttachment(a.url, a.type)}>
                    <div className="border-secondary-light relative h-16 w-16 overflow-hidden rounded-md border">
                      <div>
                        <Viewer
                          imgUrl={a.url}
                          properties={a.metadata.properties}
                          prefix="attachment-small-"
                          size={{ height: '64px', width: '64px' }}
                        />
                      </div>

                      <div className="h-[10px] w-[1px] overflow-scroll">
                        <Viewer
                          imgUrl={a.url}
                          properties={a.metadata.properties}
                          prefix="attachment-large"
                          size={{
                            height: '512px',
                            width: '512px',
                          }}
                        />
                      </div>

                      <CnAttachmentOverlay />
                    </div>
                  </div>
                )}

                {a.type !== 'CORNERSTONE' && (
                  <div onClick={() => removeAttachment(a.url, a.type)}>
                    <div className="border-secondary-light relative h-16 w-16 overflow-hidden rounded-md border">
                      <img
                        src={a.type === 'CLIPBOARD' ? a.url : `${process.env.S3_URL}/${a.url}`}
                        alt="conclusion attachment"
                        crossOrigin="anonymous"
                        className="absolute top-1/2 left-1/2 max-h-full max-w-full -translate-x-1/2 -translate-y-1/2"
                      />

                      <CnAttachmentOverlay />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function CnAttachmentOverlay() {
  return (
    <div className="lef-0 absolute top-0 h-full w-full cursor-pointer opacity-0 hover:opacity-100">
      <div className="h-full w-full bg-black opacity-50"></div>

      <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <IconX color="white" />
      </span>
    </div>
  );
}
