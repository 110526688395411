import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, InferType, array, mixed } from 'yup';
import CnEditor from './components/CnEditor';
import CnAttachments from './components/CnAttachments';
import DownloadPdfBtn from './components/btns/DownloadPdfBtn';
import SaveTemplateBtn from './components/btns/SaveTemplateBtn';
import CopyTextBtn from './components/btns/CopyTextBtn';
import { appendAttachmentsBase } from './utils';
import { Tabs, ActionIcon, Flex, Button } from '@mantine/core';
import { IconSquareX, IconPlus } from '@tabler/icons-react';
import CnServices from './components/CnServices';
import { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from '../../../../../../medreview/server/src';
import { trpc } from '../../../../../platform/app/trpc';
import { SubmitCnBtn } from './components/btns/SubmitCnBtn';
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';

export default function CnDefault({
  onSave,
  study,
}: {
  onSave?: () => void;
  study: NonNullable<inferRouterOutputs<AppRouter>['study']['get']>;
}) {
  const cns = study.conclusion || [];
  const [newTabs, setNewTabs] = useState(cns.length ? [] : [1]);
  const [activeTab, setActiveTab] = useState(cns.length ? cns[0].id : newTabs[0]);

  return (
    <Tabs
      className={study.ohif_id ? 'h-[calc(-90px+100vh)]' : ''}
      value={activeTab.toString()}
    >
      <Tabs.List>
        {study?.conclusion.map((tab, i) => (
          <Tabs.Tab
            key={tab.id}
            value={tab.id.toString()}
            onClick={() => setActiveTab(tab.id)}
          >
            Заключение {i + 1}
          </Tabs.Tab>
        ))}

        {newTabs.map((tab, i) => (
          <Tabs.Tab
            key={tab}
            value={tab.toString()}
            onClick={() => setActiveTab(tab)}
          >
            <Flex className="items-center gap-2">
              Заключение {cns.length + i + 1}
              <ActionIcon
                size="sm"
                onClick={e => {
                  e.stopPropagation();
                  const i = newTabs.findIndex(t => t === tab);
                  setNewTabs(newTabs.filter(t => t !== tab));
                  setActiveTab(newTabs[i - 1] || newTabs[i + 1] || cns[cns.length - 1].id);
                }}
              >
                <IconSquareX stroke={2} />
              </ActionIcon>
            </Flex>
          </Tabs.Tab>
        ))}

        <Tabs.Tab
          value="new"
          onClick={() => {
            const newTab = (newTabs[newTabs.length - 1] || 0) + 1;
            setNewTabs([...newTabs, newTab]);
            setActiveTab(newTab);
          }}
        >
          <ActionIcon size="sm">
            <IconPlus stroke={2} />
          </ActionIcon>
        </Tabs.Tab>
      </Tabs.List>

      {cns.map(tab => (
        <Tabs.Panel
          key={tab.id}
          value={tab.id.toString()}
        >
          <CnDefaultForm
            onSave={() => onSave && onSave()}
            conclusionId={+tab.id}
            study={study}
            onCnSelect={id => setActiveTab(id)}
          />
        </Tabs.Panel>
      ))}

      {newTabs.map(tab => (
        <Tabs.Panel
          key={tab}
          value={tab.toString()}
        >
          <CnDefaultForm
            onSave={async cnId => {
              setNewTabs(newTabs.filter(t => t !== tab));
              setActiveTab(cnId);
              onSave && onSave();
            }}
            study={study}
            onCnSelect={id => setActiveTab(id)}
          />
        </Tabs.Panel>
      ))}
    </Tabs>
  );
}

export function CnDefaultForm({
  onSave,
  conclusionId,
  study,
  onCnSelect,
}: {
  onSave: (id: number) => void;
  conclusionId?: number;
  study: NonNullable<inferRouterOutputs<AppRouter>['study']['get']>;
  onCnSelect: (id: number) => void;
}) {
  const trpcUtils = trpc.useUtils();
  const [wantsToEdit, setWantsToEdit] = useState(false);

  const user = trpc.general.getUser.useQuery();
  const conclusion = study.conclusion.find(c => c.id === conclusionId);

  const defaultForm = useForm({
    resolver: yupResolver(defaultFormSchema),
    values: {
      conclusion_text: conclusion?.conclusion_text || '',
      attachments:
        conclusion?.conclusion_images.map(ci => {
          return { url: ci.image_path || '', type: 'EXISTING' } as const;
        }) || [],
      study_service_ids: conclusion?.service_study.map(ss => ss.id.toString()) || [],
    },
  });

  const createConclusion = trpc.cn.upsert.useMutation({
    onSettled: async (upsertedCnId, error) => {
      modals.closeAll();
      setWantsToEdit(false);

      notifications.show({
        message: error
          ? 'Произошла ошибка при сохранении заключения. Обратитесь к администратору системы для решения проблемы'
          : 'Заключение успешно сохранено',
        color: error ? 'red' : 'green',
        autoClose: 5000,
      });

      if (!error && upsertedCnId) {
        await trpcUtils.study.get.invalidate();
        onSave(upsertedCnId);
      }
    },
  });

  async function onSubmit(data: DefaultForm, send_message: boolean) {
    const a = await appendAttachmentsBase(data['attachments']);

    createConclusion.mutate({
      studyId: study.id,
      conclusionId: conclusion?.id || null,
      attachments: a,
      existingAttachments: !study.ohif_id
        ? []
        : data['attachments'].filter(a => a.type === 'EXISTING').map(a => a.url),
      conclusionText: data.conclusion_text,
      studyServiceIds: data['study_service_ids'].map(s => +s!),
      sendMessage: send_message,
    });
  }

  const disabled =
    !user.data?.canMakeConclusions || createConclusion.isLoading || (!!conclusion && !wantsToEdit);

  return (
    <div className="pl-5 pr-8 pb-5">
      <FormProvider {...defaultForm}>
        <CnServices
          study={study}
          conclusionId={conclusionId}
          onCnSelect={onCnSelect}
          disabled={disabled}
        />

        <CnEditor disabled={disabled} />
        {study.ohif_id && <CnAttachments disabled={disabled} />}

        <div className="mt-4 flex items-center justify-between gap-3">
          <div className="flex items-center gap-3">
            <DownloadPdfBtn
              conclusion={conclusion}
              study={study}
            />
            <SaveTemplateBtn study={study} />
            <CopyTextBtn />
          </div>

          {!disabled && (
            <SubmitCnBtn
              onSubmit={onSubmit}
              study={study}
            />
          )}

          {user.data?.canMakeConclusions && disabled && (
            <Button
              size="xs"
              onClick={() => setWantsToEdit(true)}
            >
              Редактировать
            </Button>
          )}
        </div>
      </FormProvider>
    </div>
  );
}

export const attachmentTypes = ['CORNERSTONE', 'EXISTING', 'CLIPBOARD'] as const;

export const generalFormSchema = object({
  conclusion_text: string().required('Необходимо заполнить поле для текста заключения.'),
  attachments: array()
    .of(
      object({
        type: string<(typeof attachmentTypes)[number]>().defined().oneOf(attachmentTypes),
        url: string().required(),
        metadata: mixed(),
      })
    )
    .required(),
});

export const defaultFormSchema = generalFormSchema.shape({
  study_service_ids: array().of(string()).required(),
});

export type DefaultForm = InferType<typeof defaultFormSchema>;
