import { Types } from '@ohif/core';

const mpr: Types.HangingProtocol.Protocol = {
  id: 'mpr',
  name: 'Multi-Planar Reconstruction',
  locked: true,
  createdDate: '2021-02-23',
  modifiedDate: '2023-08-15',
  availableTo: {},
  editableBy: {},
  // Unknown number of priors referenced - so just match any study
  numberOfPriorsReferenced: 0,
  protocolMatchingRules: [],
  imageLoadStrategy: 'nth',
  callbacks: {
    // Switches out of MPR mode when the layout change button is used
    onLayoutChange: [
      {
        commandName: 'toggleHangingProtocol',
        commandOptions: { protocolId: 'mpr' },
        context: 'DEFAULT',
      },
    ],
    // Turns off crosshairs when switching out of MPR mode
    onProtocolExit: [
      {
        commandName: 'cleanUpCrosshairs',
      },
    ],
  },
  displaySetSelectors: {
    activeDisplaySet: {
      seriesMatchingRules: [
        {
          weight: 1,
          attribute: 'isReconstructable',
          constraint: {
            equals: {
              value: true,
            },
          },
          required: true,
        },
      ],
    },
  },
  stages: [
    {
      name: 'MPR 1x3',
      viewportStructure: {
        layoutType: 'grid',
        properties: {
          rows: 1,
          columns: 3,
          layoutOptions: [
            {
              x: 0,
              y: 0,
              width: 1 / 3,
              height: 1,
            },
            {
              x: 1 / 3,
              y: 0,
              width: 1 / 3,
              height: 1,
            },
            {
              x: 2 / 3,
              y: 0,
              width: 1 / 3,
              height: 1,
            },
          ],
        },
      },
      viewports: [
        {
          viewportOptions: {
            viewportId: 'mpr-axial',
            toolGroupId: 'mpr',
            viewportType: 'volume',
            orientation: 'axial',
            initialImageOptions: {
              preset: 'middle',
            },
            syncGroups: [
              {
                type: 'voi',
                id: 'mpr',
                source: true,
                target: true,
              },
            ],
          },
          displaySets: [
            {
              id: 'activeDisplaySet',
            },
          ],
        },
        {
          viewportOptions: {
            viewportId: 'mpr-sagittal',
            toolGroupId: 'mpr',
            viewportType: 'volume',
            orientation: 'sagittal',
            initialImageOptions: {
              preset: 'middle',
            },
            syncGroups: [
              {
                type: 'voi',
                id: 'mpr',
                source: true,
                target: true,
              },
            ],
          },
          displaySets: [
            {
              id: 'activeDisplaySet',
            },
          ],
        },
        {
          viewportOptions: {
            viewportId: 'mpr-coronal',
            toolGroupId: 'mpr',
            viewportType: 'volume',
            orientation: 'coronal',
            initialImageOptions: {
              preset: 'middle',
            },
            syncGroups: [
              {
                type: 'voi',
                id: 'mpr',
                source: true,
                target: true,
              },
            ],
          },
          displaySets: [
            {
              id: 'activeDisplaySet',
            },
          ],
        },
      ],
    },
  ],
};

const mprAnd3DVolumeViewport = {
  id: 'mprAnd3DVolumeViewport',
  locked: true,
  name: 'mpr',
  createdDate: '2023-03-15T10:29:44.894Z',
  modifiedDate: '2023-03-15T10:29:44.894Z',
  availableTo: {},
  editableBy: {},
  protocolMatchingRules: [],
  imageLoadStrategy: 'interleaveCenter',
  displaySetSelectors: {
    mprDisplaySet: {
      seriesMatchingRules: [
        {
          weight: 1,
          attribute: 'isReconstructable',
          constraint: {
            equals: {
              value: true,
            },
          },
          required: true,
        },
        {
          attribute: 'Modality',
          constraint: {
            equals: {
              value: 'CT',
            },
          },
          required: true,
        },
      ],
    },
  },
  stages: [
    {
      id: 'mpr3Stage',
      name: 'mpr',
      viewportStructure: {
        layoutType: 'grid',
        properties: {
          rows: 2,
          columns: 2,
        },
      },
      viewports: [
        {
          viewportOptions: {
            toolGroupId: 'mpr',
            viewportType: 'volume',
            orientation: 'axial',
            initialImageOptions: {
              preset: 'middle',
            },
            syncGroups: [
              {
                type: 'voi',
                id: 'mpr',
                source: true,
                target: true,
              },
            ],
          },
          displaySets: [
            {
              id: 'mprDisplaySet',
            },
          ],
        },
        {
          viewportOptions: {
            toolGroupId: 'volume3d',
            viewportType: 'volume3d',
            orientation: 'coronal',
            customViewportProps: {
              hideOverlays: true,
            },
          },
          displaySets: [
            {
              id: 'mprDisplaySet',
              options: {
                displayPreset: 'CT-Bone',
              },
            },
          ],
        },
        {
          viewportOptions: {
            toolGroupId: 'mpr',
            viewportType: 'volume',
            orientation: 'coronal',
            initialImageOptions: {
              preset: 'middle',
            },
            syncGroups: [
              {
                type: 'voi',
                id: 'mpr',
                source: true,
                target: true,
              },
            ],
          },
          displaySets: [
            {
              id: 'mprDisplaySet',
            },
          ],
        },
        {
          viewportOptions: {
            toolGroupId: 'mpr',
            viewportType: 'volume',
            orientation: 'sagittal',
            initialImageOptions: {
              preset: 'middle',
            },
            syncGroups: [
              {
                type: 'voi',
                id: 'mpr',
                source: true,
                target: true,
              },
            ],
          },
          displaySets: [
            {
              id: 'mprDisplaySet',
            },
          ],
        },
      ],
    },
  ],
};

const mgCCProtocol = {
  id: 'mgCC',
  name: 'mammo',
  imageLoadStrategy: 'interleaveTopToBottom',
  protocolMatchingRules: [
    { attribute: 'ModalitiesInStudy', constraint: { contains: ['MG', 'DX'] } },
  ],
  displaySetSelectors: {
    isRCC: {
      seriesMatchingRules: [
        { weight: 3, attribute: 'isRCC', constraint: { equals: { value: true } } },
        { weight: 2, attribute: 'SeriesNumber', constraint: { equals: { value: 1 } } },
        { weight: 1, attribute: 'any', constraint: { equals: { value: true } } },
      ],
    },
    isLCC: {
      seriesMatchingRules: [
        { weight: 3, attribute: 'isLCC', constraint: { equals: { value: true } } },
        { weight: 2, attribute: 'SeriesNumber', constraint: { equals: { value: 2 } } },
        { weight: 1, attribute: 'any', constraint: { equals: { value: true } } },
      ],
    },
  },
  stages: [
    {
      name: 'default',
      viewportStructure: { layoutType: 'grid', properties: { rows: 1, columns: 2 } },
      viewports: [{ displaySets: [{ id: 'isRCC' }] }, { displaySets: [{ id: 'isLCC' }] }],
    },
  ],
  numberOfPriorsReferenced: -1,
};

const mgMLOProtocol = {
  id: 'mgMLO',
  name: 'mammo',
  imageLoadStrategy: 'interleaveTopToBottom',
  protocolMatchingRules: [
    { attribute: 'ModalitiesInStudy', constraint: { contains: ['MG', 'DX'] } },
  ],
  displaySetSelectors: {
    isRMLO: {
      seriesMatchingRules: [
        { weight: 3, attribute: 'isRMLO', constraint: { equals: { value: true } } },
        { weight: 2, attribute: 'SeriesNumber', constraint: { equals: { value: 3 } } },
        { weight: 1, attribute: 'any', constraint: { equals: { value: true } } },
      ],
    },
    isLMLO: {
      seriesMatchingRules: [
        { weight: 3, attribute: 'isLMLO', constraint: { equals: { value: true } } },
        { weight: 2, attribute: 'SeriesNumber', constraint: { equals: { value: 4 } } },
        { weight: 1, attribute: 'any', constraint: { equals: { value: true } } },
      ],
    },
  },
  stages: [
    {
      name: 'default',
      viewportStructure: { layoutType: 'grid', properties: { rows: 1, columns: 2 } },
      viewports: [{ displaySets: [{ id: 'isRMLO' }] }, { displaySets: [{ id: 'isLMLO' }] }],
    },
  ],
  numberOfPriorsReferenced: -1,
};

function getHangingProtocolModule() {
  return [
    {
      name: mpr.id,
      protocol: mpr,
    },
    {
      name: mprAnd3DVolumeViewport.id,
      protocol: mprAnd3DVolumeViewport,
    },
    {
      name: mgCCProtocol.id,
      protocol: mgCCProtocol,
    },
    {
      name: mgMLOProtocol.id,
      protocol: mgMLOProtocol,
    },
  ];
}

export default getHangingProtocolModule;
