import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import NavBar from '../NavBar';
import Icon from '../Icon';
import IconButton from '../IconButton';
import Dropdown from '../Dropdown';
import { trpc } from '../../../../app/trpc';
import { notifications } from '@mantine/notifications';
import { Text, Switch, Flex, Title, Button } from '@mantine/core';
import { useGetStudy } from '../../../../app/src/api/studies';
import { useSearchParams } from '../../../../app/src/hooks';

function Header({
  children,
  menuOptions,
  isReturnEnabled,
  onClickReturnButton,
  isSticky,
  WhiteLabeling,
  ...props
}): ReactNode {
  const [checked, setChecked] = useState(!!localStorage.getItem('localOrthanc'));

  const { t } = useTranslation('Header');
  const params = useSearchParams();

  const user = trpc.general.getUser.useQuery();
  const study = useGetStudy();

  // TODO: this should be passed in as a prop instead and the react-router-dom
  // dependency should be dropped
  const onClickReturn = () => {
    if (isReturnEnabled && onClickReturnButton) {
      onClickReturnButton();
    }
  };

  async function changeStorage(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.checked) {
      localStorage.removeItem('localOrthanc');
      setChecked(false);
      return;
    }

    if (e.target.checked) {
      setChecked(true);

      const orthancConfig = user.data?.organization_user[0]?.organizations?.orthanc_config[0];

      if (!orthancConfig?.dicom_hostname) {
        notifications.show({
          message: 'Нет доступного локального хранилища',
          color: 'orange',
        });

        setChecked(false);

        return;
      }

      let localOrthancStatus;
      let timeoutId;

      try {
        const controller = new AbortController();
        timeoutId = setTimeout(() => controller.abort(), 5000);

        const response = await fetch(`${orthancConfig.dicom_hostname}/tools/now-local`, {
          method: 'GET',
          signal: controller.signal,
          headers: {
            Authorization: `Basic ${btoa(
              process.env.ORTHANC_LOGIN + ':' + process.env.ORTHANC_PASSWORD
            )}`,
          },
        });

        if (!response.ok) {
          throw new Error();
        }

        localOrthancStatus = 'success';
      } catch (error) {
        localOrthancStatus = 'error';
      } finally {
        clearTimeout(timeoutId);
      }

      if (localOrthancStatus === 'error') {
        notifications.show({
          message:
            'Локальное хранилище снимков недоступно с Вашего устройства. Для устранения проблемы обратитесь к Администратору системы.',
          color: 'orange',
        });

        setChecked(false);

        return;
      }

      localStorage.setItem('localOrthanc', JSON.stringify(orthancConfig));
    }
  }

  function openPrintModal() {
    // UIModalService.show({
    //   content: PrintModal,
    //   contentProps: {
    //     servicesManager,
    //     extensionManager,
    //   },
    //   title: 'Отправить на печать',
    // });
  }

  return (
    <NavBar
      className="justify-between border-b-4 border-black pr-5"
      isSticky={isSticky}
    >
      <div className="flex flex-1 justify-between">
        <div className="flex items-center">
          {/* // TODO: Should preserve filter/sort
              // Either injected service? Or context (like react router's `useLocation`?) */}
          <div
            className={classNames(
              'mr-3 inline-flex items-center',
              isReturnEnabled && 'cursor-pointer'
            )}
          >
            {isReturnEnabled && (
              <Icon
                name="chevron-left"
                className="text-primary-active w-8"
                onClick={onClickReturn}
              />
            )}
            <div
              className={`header-brand header-brand__small ml-4`}
              onClick={onClickReturn}
            >
              <img
                src="/assets/app/logo.png"
                alt="home"
              />
              <Title className="mt-1 text-[17px] text-white">MedReview</Title>
            </div>

            {study.data?.modality_study.some(ms => ms.modalities.name === 'MG') &&
              params.get('hangingprotocolId') === 'mgCC' && (
                <Button
                  className="ml-2 mt-1"
                  size="xs"
                  onClick={() => {
                    window.open(
                      `/viewer?StudyInstanceUIDs=${study.data?.ohif_id}&hangingprotocolId=mgMLO`,
                      '_blank'
                    );
                  }}
                >
                  Открыть 2 проекцию
                </Button>
              )}
          </div>
        </div>
        <div className="flex items-center">{children}</div>
        <div className="mt-3 flex items-center justify-end pb-2 sm:mt-0 sm:pb-0">
          <div className="mr-2 hidden sm:block lg:mr-20">
            {!children && (
              <Flex className="gap-2 text-white">
                <Text>Облачное хранилище снимков</Text>
                <Switch
                  checked={checked}
                  onChange={changeStorage}
                  classNames={{ track: 'bg-[#228be6] border-[#228be6] cursor-pointer' }}
                />
                <Text>Локальное хранилище снимков</Text>
              </Flex>
            )}
          </div>

          <Dropdown
            id="options"
            showDropdownIcon={false}
            list={menuOptions}
            alignment="right"
          >
            <IconButton
              id={'options-settings-icon'}
              variant="text"
              color="inherit"
              size="initial"
              className="text-primary-active"
            >
              <Icon name="settings" />
            </IconButton>
            <IconButton
              id={'options-chevron-down-icon'}
              variant="text"
              color="inherit"
              size="initial"
              className="text-primary-active"
            >
              <Icon name="chevron-down" />
            </IconButton>
          </Dropdown>
        </div>
      </div>
    </NavBar>
  );
}

Header.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isReturnEnabled: PropTypes.bool,
  isSticky: PropTypes.bool,
  onClickReturnButton: PropTypes.func,
  WhiteLabeling: PropTypes.object,
};

Header.defaultProps = {
  isReturnEnabled: true,
  isSticky: false,
};

export default Header;
